import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import ServiceItem from './ServiceItem'

export const query = graphql`
  {
    allStrapiServices {
      nodes {
        slug
        title
        content
        cover {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`

const ServicesContent = () => {
  const data = useStaticQuery(query)
  const {
    allStrapiServices: { nodes: services },
  } = data

  return (
    <div>
			<section className="overview-area ptb-100 pt-70">
        <div className="container">
          {services.map((service, index) => (
            <ServiceItem
              key={`service-item-${index}`}
              type={(index + 1) % 2 === 0 ? 'right' : 'left'}
              service={service}
            />
          ))}
        </div>
      </section>
    </div>
  )
}

export default ServicesContent
