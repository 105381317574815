import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import ReactMarkdown from 'react-markdown'

export const ServiceItem = props => {
  const { type, service } = props
  const { title, content, cover, slug } = service

  return type === 'left' ? (
    <div className="overview-box">
      <div className="overview-image">
        <div className="image">
          <Link to={`/services/${slug}`}>
            <GatsbyImage
              alt={title}
              image={cover.childImageSharp.gatsbyImageData}
            />
          </Link>
        </div>
      </div>

      <div className="overview-content">
        <div className="content right-content">
          <span className="sub-title"></span>
					<Link to={`/services/${slug}`}>
          	<h2>{title}</h2>
					</Link>
          <ReactMarkdown source={content} />
        </div>
      </div>
    </div>
  ) : (
    <div className="overview-box">
      <div className="overview-content">
        <div className="content">
          <span className="sub-title"></span>
					<Link to={`/services/${slug}`}>
          	<h2>{title}</h2>
					</Link>
          <ReactMarkdown source={content} />
        </div>
      </div>

      <div className="overview-image">
        <div className="image">
          <Link to={`/services/${slug}`}>
            <GatsbyImage
              alt={title}
              image={cover.childImageSharp.gatsbyImageData}
            />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ServiceItem
