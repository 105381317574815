import React from 'react'
import Layout from '../components/App/Layout'
import Navbar from '../components/Common/Navbar'
import PageBanner from '../components/Common/PageBanner'
import Footer from '../components/Common/Footer'
import ServicesContent from '../components/Services/ServicesContent'
import { graphql, useStaticQuery } from 'gatsby'
import NeedHelp from '../components/NeedHelp/NeedHelp'

const query = graphql`
  {
    strapiSeos(slug: { eq: "services" }) {
      Meta {
        title
        description
        keywords
      }
      TwitterMeta {
        card
      }
    }
  }
`

const AboutUs = () => {
  const data = useStaticQuery(query)
  const {
    strapiSeos: { Meta: metaTags, TwitterMeta },
  } = data

  return (
    <Layout metaTags={metaTags} twitterCard={TwitterMeta}>
      <Navbar />

      <PageBanner
        pageTitle="Services"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Services"
      />

      <ServicesContent />

      <NeedHelp />

      <Footer />
    </Layout>
  )
}

export default AboutUs
